import React from "react";
import SEO from "../components/seo";
import HeroGeneric from "../components/HeroGeneric";
import Layout from "../components/layout";
import HelpTiles from "../components/HelpTiles";

export default function PromotionsIndex({ entry, promotions }) {
	const {
		heroTitle,
		heroText,
		indexPageBlocks,
		metaTitle,
		metaDescription,
		metaShareImage,
	} = entry ?? [];

	let items = indexPageBlocks?.map((block) => {
		const termsEntry =
			promotions.length && block.indexPageBlockButton.type === "entry"
				? promotions.filter(
						(item) => item.id === block.indexPageBlockButton.element.id
				  )[0]?.promotionTermsPage
				: null;
		return {
			title: block.indexPageBlockTitle,
			text: block.indexPageBlockText,
			cta: block.indexPageBlockButton,
			termsEntry,
		};
	});

	return (
		<Layout className="layout--helpIndex">
			<SEO
				title={metaTitle}
				description={metaDescription}
				image={metaShareImage}
			/>

			<HeroGeneric
				title={heroTitle}
				text={heroText}
				colorTheme="dark"
				nextTheme="light"
			/>

			<HelpTiles items={items} />
		</Layout>
	);
}
