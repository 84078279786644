import { graphql } from "gatsby";
import PromotionsIndex from "../../templates/promotionsIndex";

const PromotionsIndexPage = ({ data }) => {
	const entry = data?.craft?.promotions;
	const allPromotions = data?.craft?.allPromotions;
	return <PromotionsIndex entry={entry} promotions={allPromotions} />;
};

export const pageQuery = graphql`
	query promotionsIndexPage {
		craft {
			promotions: entry(slug: "promotions", type: "promotionsIndex") {
				... on CraftCMS_promotionsIndex_promotionsIndex_Entry {
					heroTitle
					heroText
					indexPageBlocks {
						... on CraftCMS_indexPageBlocks_indexPageBlock_BlockType {
							id
							indexPageBlockTitle
							indexPageBlockText
							indexPageBlockButton {
								element {
									slug
									uri
									id
								}
								url
								type
								title
								text
								target
								customText
							}
						}
					}
					metaTitle
					metaShareImage {
						filename
						url
					}
					metaDescription
				}
			}
			allPromotions: entries(section: "promotions") {
				id
				... on CraftCMS_promotions_promotionLandingPage_Entry {
					promotionTermsPage {
						id
						url
						uri
					}
				}
			}
		}
	}
`;

export default PromotionsIndexPage;
